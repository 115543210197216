<template>
  <section
    id="cuckoo-speed"
    class="cuckoo-speed"
  >
    <div
      v-if="loading"
      class="content-spinner"
    >
      <div class="d-flex align-items-center">
        <b-spinner
          small
          type="grow"
          variant="secondary"
        />
        <b-spinner
          type="grow"
          variant="dark"
        />
        <b-spinner
          small
          type="grow"
          variant="secondary"
        />
      </div>
    </div>

    <div
      v-if="showError"
      class="content-error d-flex align-items-center"
    >
      <div>
        <h1 class="mt-4 mb-4">We're not available at your home yet</h1>
      </div>

      <div>
        <h3 class="mt-2 mb-2">this is due to one of three reasons</h3>
      </div>

      <div>
        <div>- Openreach cannot service your addrees</div>
        <div>- Openreach have not upgraded yoour address to fibre to cabinet</div>
        <div>- Our network has not freed up your exchange</div>
      </div>
    </div>

    <div
      v-if="showData"
    >
      <div class="text-center">
        <h1 class="mt-5">
          Great news! You can get our fibre broadband at
        </h1>
        <p class="mb-5 pb-75" v-if="user">
          <span v-if="user.moves">{{user.moves[0].moveDetails.moveToAddress}}</span>
        </p>
      </div>

      <b-row class="pricing-card">
        <b-col>
          <b-row>
            <b-col
              xs="12"
              sm="6"
              md="6"
              lg="4"
              v-if="cuckoo.getALKAvailability && cuckoo.getALKAvailability.fttp.availableFTTPProducts.length === 0"
            >
              <b-card
                class="popular cuckoo-speed-card"
              >
                <div
                  class="margin-buttom"
                >
                  <span
                    class="cuckoo-badge"
                  >
                    MB FIBRE
                  </span>
                </div>
                <h2 class="mt-2 mb-2 bold">Fast</h2>
                <div class="content-card-information">
                  <b-card-text>
                    Brilliant for smaller homes with up to 5 devices. Stream in 4K on 2 devices at the same time, game and download big files.
                  </b-card-text>

                  <b-list-group class="list-group-circle text-left">
                    <b-list-group-item
                      v-if="cuckoo.getALKAvailability"
                      class="bold"
                    >
                      {{cuckoo.getALKAvailability.worstMaxDownload}} - {{cuckoo.getALKAvailability.bestMaxDownload}} Mb/s avg. download
                    </b-list-group-item>
                    <b-list-group-item
                      v-if="cuckoo.getALKAvailability"
                      class="bold"
                    >
                      {{cuckoo.getALKAvailability.worstMaxUpload}} - {{cuckoo.getALKAvailability.bestMinUpload}} Mb/s avg. upload
                    </b-list-group-item>
                    <b-list-group-item>
                      Fibre to your street
                    </b-list-group-item>
                    <b-list-group-item>
                      Landline included
                    </b-list-group-item>
                  </b-list-group>
                </div>

                <div class="cuckoo-plan mt-2 d-flex">
                  <sup class="font-medium-1 font-weight-bold">£</sup>
                  <span class="font-weight-bolder text-primary">29.99</span>
                  <sub class="font-medium-1 font-weight-bold mt-1">/month</sub>
                </div>

                <b-button
                  block
                  class="mt-2 cuckoo-button"
                  :to="{ name: 'dashboard-cuckoo-contact' }"
                >
                  I want this speed
                </b-button>
              </b-card>
            </b-col>



            <b-col
              xs="12"
              sm="6"
              md="6"
              lg="4"
            >
              <b-card
                class="cuckoo-speed-card"
                :class="{
                  'cuckoo-speed-card-disabled': cuckoo.getALKAvailability && cuckoo.getALKAvailability.fttp.availableFTTPProducts.length === 0
                }"
              >
                <div
                  class="margin-buttom"
                >
                  <span
                    class="cuckoo-badge"
                    :class="{
                      'cuckoo-badge-disabled': cuckoo.getALKAvailability && cuckoo.getALKAvailability.fttp.availableFTTPProducts.length === 0
                    }"
                  >
                    115MB FULL FIBRE
                  </span>
                </div>
                <h2 class="mt-2 mb-2 bold">
                  Really Fast
                </h2>

                <div
                  class="content-card-information"
                  :class="{
                    'd-none': cuckoo.getALKAvailability && cuckoo.getALKAvailability.fttp.availableFTTPProducts.length === 0
                  }"
                >
                  <b-card-text>
                    Wow. Very fast. Lets 5+ devices stream  in 4k simultaneously, game and easily download very large Gb files.
                  </b-card-text>
                  <b-list-group class="list-group-circle text-left">
                    <b-list-group-item
                      class="bold"
                    >
                      {{cuckooReallyFast.minDownload}} - {{cuckooReallyFast.maxDownload}} Mb/s avg. download
                    </b-list-group-item>
                    <b-list-group-item
                      class="bold"
                    >
                      {{cuckooReallyFast.minUpload}} - {{cuckooReallyFast.maxUpload}} Mb/s avg. upload
                    </b-list-group-item>
                    <b-list-group-item>
                      Fibre straigth to your home
                    </b-list-group-item>
                    <b-list-group-item>
                      No landline needed
                    </b-list-group-item>
                  </b-list-group>
                </div>

                <div
                  class="content-card-information content-card-information-disabled"
                  v-if="cuckoo.getALKAvailability && cuckoo.getALKAvailability.fttp.availableFTTPProducts.length === 0"
                >
                  <h4>
                    Sorry, full-fibre hasn't been connected to your home yet.
                  </h4>
                  <p>
                    Choose our Fast 80Mb speed today and we'll let you know when you can upgrade to full-fibre speeds
                  </p>
                </div>

                <div class="cuckoo-plan mt-2 d-flex">
                  <span class="font-medium-1 font-weight-bold">£</span>
                  <span class="font-weight-bolder text-primary">39.99</span>
                  <sub class="font-medium-1 font-weight-bold mt-1">/month</sub>
                </div>

                <b-button
                  block
                  class="mt-2 cuckoo-button"
                  :to="{ name: 'dashboard-cuckoo-contact' }"
                  v-if="cuckoo.getALKAvailability && cuckoo.getALKAvailability.fttp.availableFTTPProducts.length !== 0"
                >
                  I want this speed
                </b-button>

                <b-button
                  block
                  class="mt-2 cuckoo-button-disabled"
                  disabled
                  v-if="cuckoo.getALKAvailability && cuckoo.getALKAvailability.fttp.availableFTTPProducts.length === 0"
                >
                  Not available yet
                </b-button>
              </b-card>
            </b-col>

            <b-col
              xs="12"
              sm="6"
              md="6"
              lg="4"
            >
              <b-card
                class="cuckoo-speed-card"
                :class="{
                  'cuckoo-speed-card-disabled': cuckoo.getALKAvailability && cuckoo.getALKAvailability.fttp.availableFTTPProducts.length === 0
                }"
              >
                <div
                  class="margin-buttom"
                >
                  <span
                    class="cuckoo-badge"
                    :class="{
                      'cuckoo-badge-disabled': cuckoo.getALKAvailability && cuckoo.getALKAvailability.fttp.availableFTTPProducts.length === 0
                    }"
                  >
                    1GB FULL FIBRE
                  </span>
                </div>
                <h2 class="mt-2 mb-2 bold">
                  Eggceptional
                </h2>

                <div
                  class="content-card-information"
                  :class="{
                    'd-none': cuckoo.getALKAvailability && cuckoo.getALKAvailability.fttp.availableFTTPProducts.length === 0
                  }"
                >
                  <b-card-text>
                    Life-changingly fast. lest 10+ decives across large home stream simultaneosly in 4k, game and download all at the same time.
                  </b-card-text>
                  <b-list-group class="list-group-circle text-left">
                    <b-list-group-item
                      class="bold"
                    >
                      {{cuckooEggceptional.minDownload}} - {{cuckooEggceptional.maxDownload}} Mb/s avg. download
                    </b-list-group-item>
                    <b-list-group-item
                      class="bold"
                    >
                      {{cuckooEggceptional.minUpload}} - {{cuckooEggceptional.maxUpload}} Mb/s avg. upload
                    </b-list-group-item>
                    <b-list-group-item>
                      Fibre straigth to your home
                    </b-list-group-item>
                    <b-list-group-item>
                      No landline needed
                    </b-list-group-item>
                  </b-list-group>
                </div>

                <div
                  class="content-card-information content-card-information-disabled"
                  v-if="cuckoo.getALKAvailability && cuckoo.getALKAvailability.fttp.availableFTTPProducts.length === 0"
                >
                  <h4>
                    Sorry, full-fibre hasn't been connected to your home yet.
                  </h4>
                  <p>
                    Choose our Fast 80Mb speed today and we'll let you know when you can upgrade to full-fibre speeds
                  </p>
                </div>

                <div class="cuckoo-plan mt-2 d-flex">
                  <span class="font-medium-1 font-weight-bold">£</span>
                  <span class="font-weight-bolder text-primary">54.99</span>
                  <sub class="font-medium-1 font-weight-bold mt-1">/month</sub>
                </div>

                <b-button
                  block
                  class="mt-2 cuckoo-button"
                  :to="{ name: 'dashboard-cuckoo-contact' }"
                  v-if="cuckoo.getALKAvailability && cuckoo.getALKAvailability.fttp.availableFTTPProducts.length !== 0"
                >
                  I want this speed
                </b-button>

                <b-button
                  block
                  class="mt-2 cuckoo-button-disabled"
                  disabled
                  v-if="cuckoo.getALKAvailability && cuckoo.getALKAvailability.fttp.availableFTTPProducts.length === 0"
                >
                  Not available yet
                </b-button>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row
        class="row-descriptions"
      >
        <b-col
          xs="12"
          md="6"
          v-for="item in cuckooInfo.descriptions" :key="item.id"
        >
          <h2>{{item.title}}</h2>
          <p>{{item.info}}</p>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import {
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BImg,
  BCardText,
  BListGroup,
  BListGroupItem,
  BButton,
  BBadge,
  BSpinner,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import axiosCustom from '@axios'

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BCard,
    BBadge,
    BSpinner,
    BImg,
  },
  data() {
    return {
      user: {},
      cuckooInfo: {},
      showError: false,
      loading: true,
      showData: false,
      cuckoo: {},
      cuckooEggceptional: {
        show: false,
        minUpload: 0,
        maxUpload: 0,
        minDownload: 0,
        maxDownload: 0,
      },
      cuckooReallyFast: {
        show: false,
        minUpload: 0,
        maxUpload: 0,
        minDownload: 0,
        maxDownload: 0,
      }
    }
  },
  created() {
    this.$http.get('/cuckoo/speed').then(res => { this.cuckooInfo = res.data })
    this.loading = true;

    const userData = getUserData()
    this.user = userData

    axiosCustom.get(`cuckoo/getProducts?email=${userData.email}`)
    .then( ({data}) => {
      if(data.plans.data.getALKAvailability === null){
        this.showError = true
        return
      }

      this.showData = true
      this.cuckoo = data.plans.data

      if(data.plans.data.getALKAvailability.fttp.availableFTTPProducts.length){
        data.plans.data.getALKAvailability.fttp.availableFTTPProducts.forEach( element => {
          const minUpload = element.throughputSpeeds.find( row => row.attributeName === "MinUpstreamThreshold")?.value ?? 0;
          const maxUpload = element.throughputSpeeds.find( row => row.attributeName === "MaxUpstreamQuietHour")?.value ?? 0;
          const minDownload = element.throughputSpeeds.find( row => row.attributeName === "BottomDownstreamPeakHour")?.value ?? 0;
          const maxDownload = element.throughputSpeeds.find( row => row.attributeName === "TopDownstreamPeakHour")?.value ?? 0;

          if(element.productName === "cuckoo-fttp-115"){
            this.cuckooReallyFast = {
              show: true,
              minUpload,
              maxUpload,
              minDownload,
              maxDownload
            }
          }
          if(element.productName === "cuckoo-fttp-1000"){
            this.cuckooEggceptional = {
              show: true,
              minUpload,
              maxUpload,
              minDownload,
              maxDownload
            }
          }
        })
      }
    })
    .catch((error) => {
      this.showError = true
    })
    .finally(() => {
      this.loading = false
    })
  },
}
</script>


<style lang="scss">
  @import '@core/scss/vue/pages/page-pricing.scss';
  .cuckoo-speed{
    .bold{
      font-weight: bold;
    }
    .content-spinner{
      display: flex;
      justify-content: center;
      height: 70vh;
    }
    .content-error{
      flex-direction: column;
    }

    .content-card-information{
      min-height: 270px;
      height: auto;
    }

    .content-card-information-disabled{
      background-color: #ddd;
      padding: 20px;
      h4{
        color:#000;
      }
    }

    .cuckoo-speed-card-disabled{
      background-color: rgb(230, 230, 230);
    }

    .cuckoo-badge-disabled{
      background-color: rgb(213, 213, 213) !important;
    }

    .cuckoo-button-disabled{
      border-radius: 100px;
      background-color: rgb(178, 178, 178) !important;
      color: #FFF !important;
      padding: 20px 25px;
      text-decoration: none;
      font-weight: 700;
      border: none;
      }

    .steep{
      display: flex;
      align-items: center;
    }

    .margin-buttom{
      margin-bottom: 10px;
    }

    .row-descriptions{
      background-color: rgb(255, 248, 171) !important;
      border-radius: 20px;
      padding: 2rem;
    }

    .popular{
      border: 2px solid rgb(255, 232, 2) !important;
    }

    .cuckoo-speed-card{
      border-radius: 20px;

      .cuckoo-badge{
        background-color: rgb(255, 232, 2);
        color: #000;
        border-radius: 36px;
        font-size: 13px;
        font-weight: 700;
        margin-bottom: 10px;
        padding: 4px 11px;
        text-transform: uppercase;
        width: fit-content;
      }

      .cuckoo-button{
        border-radius: 100px;
        background-color: rgb(5, 128, 229) !important;
        color: rgb(255, 255, 255) !important;
        padding: 20px 25px;
        text-decoration: none;
        font-weight: 700;
        border: none;
      }
    }

    .cuckoo-plan {
      sup {
        top: -1.5rem;
        left: 0.2rem;
      }
      span {
        font-size: 2rem;
        line-height: 0.8;
        color: #000 !important;
      }
      sub {
        bottom: 0;
        right: 0.14rem;
      }
    }
  }
</style>
